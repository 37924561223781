var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { Box, IconButton, Tooltip, MenuItem, } from '@mui/material';
import { getCPFFormatado, getDataFormatada, ExportarExcel, ExportarPDF, MODOS } from '../../utils';
import LogoutIcon from '@mui/icons-material/Logout';
import ContainerPainel from '../../components/container_painel';
import AuthContext from '../../contexts/auth';
import { ROUTES, PAGINAS } from '../../routes';
import BarraPesquisar from '../../components/grid_pesquisar';
import GridComp from '../../components/grid';
import { PaginationModelDefault } from '../../components/grid';
import { BoxPrincipal, BoxSuperior, BoxGrid } from '../../components/grid_box';
import { getContratosTrabalho, getCombosListarContratos, gerarExcelContratos, gerarPDFContratos } from './contratos_trabalho.service';
import { STATUS_API } from '../../api';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useNavigate } from 'react-router-dom';
import InputDados from '../../components/input_dados';
export var ContratosTrabalhoListar = function (props) {
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var navigate = useNavigate();
    var _b = useState([]), InstituicoesDisponiveis = _b[0], setInstituicoesDisponiveis = _b[1];
    var _c = useState(-1), BuscaInstituicaoSelecionada = _c[0], setBuscaInstituicaoSelecionada = _c[1];
    var _d = useState([]), TiposStatusContratosDisponiveis = _d[0], setTiposStatusContratosDisponiveis = _d[1];
    var _e = useState('-1'), BuscaStatusContratosSelecionado = _e[0], setBuscaStatusContratosSelecionado = _e[1];
    var _f = useState(''), BuscaDataInicio = _f[0], setBuscaDataInicio = _f[1];
    var _g = useState(''), BuscaDataFim = _g[0], setBuscaDataFim = _g[1];
    var _h = useState(''), TextoBusca = _h[0], setTextoBusca = _h[1];
    var _j = useState([]), OfertasVagaTrabalhos = _j[0], setOfertasVagaTrabalhos = _j[1];
    var _k = useState(0), TotalRegistros = _k[0], setTotalRegistros = _k[1];
    var _l = React.useState(PaginationModelDefault), PaginationModel = _l[0], setPaginationModel = _l[1];
    var getAcoesFuncionalidade = (useContext(AuthContext) || { getAcoesFuncionalidade: function () { return []; } }).getAcoesFuncionalidade;
    var _m = React.useState(getAcoesFuncionalidade(PAGINAS.CONTRATOS_TRABALHO)), AcoesFuncionalidade = _m[0], setAcoesFuncionalidade = _m[1];
    useEffect(function () {
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function (TextoBuscaParm, BuscaStatusContratosSelecionadoParm, BuscaInstituicaoSelecionadaParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModelParm) {
        if (TextoBuscaParm === void 0) { TextoBuscaParm = TextoBusca; }
        if (BuscaStatusContratosSelecionadoParm === void 0) { BuscaStatusContratosSelecionadoParm = BuscaStatusContratosSelecionado; }
        if (BuscaInstituicaoSelecionadaParm === void 0) { BuscaInstituicaoSelecionadaParm = BuscaInstituicaoSelecionada; }
        if (BuscaDataInicioParm === void 0) { BuscaDataInicioParm = BuscaDataInicio; }
        if (BuscaDataFimParm === void 0) { BuscaDataFimParm = BuscaDataFim; }
        if (PaginationModelParm === void 0) { PaginationModelParm = PaginationModel; }
        return __awaiter(void 0, void 0, void 0, function () {
            var CombosContratos, instituicoes, status_contratos, Resposta, dados, total_registros;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        if (!(InstituicoesDisponiveis.length <= 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, getCombosListarContratos()];
                    case 1:
                        CombosContratos = _a.sent();
                        if (CombosContratos.Status == STATUS_API.ERRO) {
                            alert(CombosContratos.Mensagem);
                            setIsLoading(false);
                            return [2 /*return*/];
                        }
                        instituicoes = CombosContratos.instituicoes, status_contratos = CombosContratos.status_contratos;
                        setTiposStatusContratosDisponiveis(status_contratos);
                        setInstituicoesDisponiveis(instituicoes);
                        _a.label = 2;
                    case 2: return [4 /*yield*/, getContratosTrabalho(TextoBuscaParm == '' ? null : TextoBuscaParm, BuscaStatusContratosSelecionadoParm == '-1' ? null : BuscaStatusContratosSelecionadoParm, BuscaInstituicaoSelecionadaParm == -1 ? null : BuscaInstituicaoSelecionadaParm === null || BuscaInstituicaoSelecionadaParm === void 0 ? void 0 : BuscaInstituicaoSelecionadaParm.toString(), BuscaDataInicioParm == '' ? null : BuscaDataInicioParm, BuscaDataFimParm == '' ? null : BuscaDataFimParm, PaginationModelParm)];
                    case 3:
                        Resposta = _a.sent();
                        if (Resposta.Status == STATUS_API.ERRO) {
                            alert(Resposta.Mensagem);
                        }
                        else {
                            dados = Resposta.dados, total_registros = Resposta.total_registros;
                            setOfertasVagaTrabalhos(dados);
                            setTotalRegistros(total_registros);
                        }
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    var filtrarGrid = function (TextoBuscaParm, BuscaStatusContratosSelecionadoParm, BuscaInstituicaoSelecionadaParm, BuscaDataInicioParm, BuscaDataFimParm) {
        if (TextoBuscaParm === void 0) { TextoBuscaParm = TextoBusca; }
        if (BuscaStatusContratosSelecionadoParm === void 0) { BuscaStatusContratosSelecionadoParm = BuscaStatusContratosSelecionado; }
        if (BuscaInstituicaoSelecionadaParm === void 0) { BuscaInstituicaoSelecionadaParm = BuscaInstituicaoSelecionada; }
        if (BuscaDataInicioParm === void 0) { BuscaDataInicioParm = BuscaDataInicio; }
        if (BuscaDataFimParm === void 0) { BuscaDataFimParm = BuscaDataFim; }
        setIsLoading(true);
        setTextoBusca(TextoBuscaParm);
        setBuscaStatusContratosSelecionado(BuscaStatusContratosSelecionadoParm);
        setBuscaInstituicaoSelecionada(BuscaInstituicaoSelecionadaParm);
        setBuscaDataInicio(BuscaDataInicioParm);
        setBuscaDataFim(BuscaDataFimParm);
        resetGrid(TextoBuscaParm, BuscaStatusContratosSelecionadoParm, BuscaInstituicaoSelecionadaParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModel);
    };
    var onPaginationModelChange = function (PaginationModelParm) {
        //console.log('PaginationModelParm : ' + JSON.stringify(PaginationModelParm));
        setPaginationModel(PaginationModelParm);
        carregarDados(TextoBusca, BuscaStatusContratosSelecionado, BuscaInstituicaoSelecionada, BuscaDataInicio, BuscaDataFim, PaginationModelParm);
    };
    var resetGrid = function (TextoBuscaParm, BuscaStatusContratosSelecionadoParm, BuscaInstituicaoSelecionadaParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModelParm) {
        if (TextoBuscaParm === void 0) { TextoBuscaParm = TextoBusca; }
        if (BuscaStatusContratosSelecionadoParm === void 0) { BuscaStatusContratosSelecionadoParm = BuscaStatusContratosSelecionado; }
        if (BuscaInstituicaoSelecionadaParm === void 0) { BuscaInstituicaoSelecionadaParm = BuscaInstituicaoSelecionada; }
        if (BuscaDataInicioParm === void 0) { BuscaDataInicioParm = BuscaDataInicio; }
        if (BuscaDataFimParm === void 0) { BuscaDataFimParm = BuscaDataFim; }
        var PaginationModelAux = PaginationModelParm !== null && PaginationModelParm !== void 0 ? PaginationModelParm : PaginationModel;
        PaginationModelAux = __assign(__assign({}, PaginationModelAux), { page: 0 });
        setPaginationModel(PaginationModelAux);
        carregarDados(TextoBuscaParm, BuscaStatusContratosSelecionadoParm, BuscaInstituicaoSelecionadaParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModelAux);
        //console.log('PaginationModelParm : ' + JSON.stringify(Pagination
    };
    var COLUNAS_FUNCIONALIDADE = [
        {
            field: 'nome',
            headerName: 'Nome',
            width: 250,
            valueGetter: function (value, row) { var _a; return (_a = row.reeducando) === null || _a === void 0 ? void 0 : _a.nome; },
        },
        {
            field: 'cpf',
            headerName: 'CPF',
            width: 150,
            valueGetter: function (value, row) { var _a; return getCPFFormatado((_a = row.reeducando) === null || _a === void 0 ? void 0 : _a.cpf); },
        },
        {
            field: 'regime',
            headerName: 'Regime de Pena',
            width: 150,
            valueGetter: function (value, row) { var _a, _b; return (_b = (_a = row.reeducando) === null || _a === void 0 ? void 0 : _a.regime_reclusao_atual) === null || _b === void 0 ? void 0 : _b.nome; },
        },
        {
            field: 'instituicao',
            headerName: 'Instituição',
            width: 250,
            valueGetter: function (value, row) { var _a, _b; return (_b = (_a = row.instituicao) === null || _a === void 0 ? void 0 : _a.dados) === null || _b === void 0 ? void 0 : _b.razao_social; },
        },
        {
            field: 'data_inicio',
            headerName: 'Data de Admissão',
            width: 150,
            valueGetter: function (value, row) { return getDataFormatada(row.data_inicio); },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
        },
        {
            field: 'Opções',
            headerName: '',
            align: 'right',
            flex: 1,
            minWidth: 80,
            renderCell: function (params) { return (_jsxs(Box, __assign({ flex: 1, display: 'flex', justifyContent: 'flex-end' }, { children: [AcoesFuncionalidade.VISUALIZAR && (_jsx(Tooltip, __assign({ title: 'Visualizar', componentsProps: { tooltip: { sx: { fontSize: '0.9rem' } } } }, { children: _jsx(Box, __assign({ bgcolor: 'primary.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 1, marginTop: -1 }, onClick: function () {
                                    navigate("".concat(ROUTES.CONTRATOS_TRABALHO, "/").concat(params.row.id, "/").concat(MODOS.VISUALIZAR));
                                } }, { children: _jsx(VisibilityOutlinedIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) })) }))), AcoesFuncionalidade.ALTERAR && params.row.status != 'ENCERRADO' && (_jsx(Tooltip, __assign({ title: 'Encerrar Contrato', componentsProps: { tooltip: { sx: { fontSize: '0.9rem' } } } }, { children: _jsx(Box, __assign({ bgcolor: 'secondary.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 1, marginTop: -1 }, onClick: function () {
                                    navigate("".concat(ROUTES.CONTRATOS_TRABALHO, "/").concat(params.row.id, "/").concat(MODOS.ALTERAR));
                                } }, { children: _jsx(LogoutIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) })) })))] }))); },
        },
    ];
    var OPCOES_FUNCIONALIDADE = {
        ROTA: ROUTES.CONTRATOS_TRABALHO,
        VISUALIZAR: false,
        ALTERAR: false,
    };
    var baixarPDF = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Doc, link;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, gerarPDFContratos(TextoBusca == '' ? null : TextoBusca, BuscaStatusContratosSelecionado == '-1' ? null : BuscaStatusContratosSelecionado, BuscaInstituicaoSelecionada == -1 ? null : BuscaInstituicaoSelecionada === null || BuscaInstituicaoSelecionada === void 0 ? void 0 : BuscaInstituicaoSelecionada.toString(), BuscaDataInicio == '' ? null : BuscaDataInicio, BuscaDataFim == '' ? null : BuscaDataFim)];
                case 1:
                    Doc = _a.sent();
                    if (Doc) {
                        try {
                            link = document.createElement('a');
                            link.href = Doc;
                            link.download = "contratos_trabalho.pdf";
                            // Adiciona o link ao documento e dispara o download
                            document.body.appendChild(link);
                            link.click();
                            // Remove o link do documento
                            document.body.removeChild(link);
                            // Libera a URL temporária
                            window.URL.revokeObjectURL(Doc);
                        }
                        catch (error) {
                            console.log('error : ' + error);
                        }
                    }
                    else {
                        alert('Erro ao gerar o pdf');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var baixarExcel = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Doc, link;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, gerarExcelContratos(TextoBusca == '' ? null : TextoBusca, BuscaStatusContratosSelecionado == '-1' ? null : BuscaStatusContratosSelecionado, BuscaInstituicaoSelecionada == -1 ? null : BuscaInstituicaoSelecionada === null || BuscaInstituicaoSelecionada === void 0 ? void 0 : BuscaInstituicaoSelecionada.toString(), BuscaDataInicio == '' ? null : BuscaDataInicio, BuscaDataFim == '' ? null : BuscaDataFim)];
                case 1:
                    Doc = _a.sent();
                    if (Doc) {
                        try {
                            link = document.createElement('a');
                            link.href = Doc;
                            link.download = "contratos_trabalho.xls";
                            // Adiciona o link ao documento e dispara o download
                            document.body.appendChild(link);
                            link.click();
                            // Remove o link do documento
                            document.body.removeChild(link);
                            // Libera a URL temporária
                            window.URL.revokeObjectURL(Doc);
                        }
                        catch (error) {
                            console.log('error : ' + error);
                        }
                    }
                    else {
                        alert('Erro ao gerar o excel');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(ContainerPainel, __assign({ pagina: PAGINAS.CONTRATOS_TRABALHO }, { children: _jsxs(BoxPrincipal, { children: [_jsxs(BoxSuperior, { children: [_jsx(BarraPesquisar, { filtrarGrid: filtrarGrid }), AcoesFuncionalidade['EXPORTAR PDF'] && _jsx(ExportarPDF, { onClick: baixarPDF }), AcoesFuncionalidade['EXPORTAR EXCEL'] && _jsx(ExportarExcel, { onClick: baixarExcel })] }), _jsxs(Box, __assign({ sx: { width: 1200, display: 'flex', flexDirection: 'row' }, mt: 2, gap: 2 }, { children: [_jsxs(InputDados, __assign({ select: true, id: 'status_vagas' // obrigatorio
                            , label: 'Status Contratos' // obrigatorio
                            , placeholder: 'Status Contratos', value: BuscaStatusContratosSelecionado, onChange: function (event) {
                                filtrarGrid(TextoBusca, event.target.value, BuscaInstituicaoSelecionada, BuscaDataInicio, BuscaDataFim);
                            }, size: 'small' }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), TiposStatusContratosDisponiveis &&
                                    TiposStatusContratosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.nome }, { children: item.nome }), item.nome)); })] })), _jsxs(InputDados, __assign({ select: true, id: 'instituicao' // obrigatorio
                            , label: 'Institui\u00E7\u00E3o' // obrigatorio
                            , placeholder: 'Institui\u00E7\u00E3o', value: BuscaInstituicaoSelecionada, onChange: function (event) {
                                //const ValorAux = event.target.value;
                                filtrarGrid(TextoBusca, BuscaStatusContratosSelecionado, event.target.value, BuscaDataInicio, BuscaDataFim);
                            }, size: 'small' }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), InstituicoesDisponiveis &&
                                    InstituicoesDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.dados.razao_social }), item.id)); })] })), _jsx(InputDados, { id: 'data_inicio', label: 'Data In\u00EDcio', mask: '99/99/9999', value: BuscaDataInicio, onChange: function (event) {
                                setBuscaDataInicio(event.target.value);
                            }, onBlur: function (event) {
                                filtrarGrid(TextoBusca, BuscaStatusContratosSelecionado, BuscaInstituicaoSelecionada, event.target.value, BuscaDataFim);
                            }, size: 'small' }), _jsx(InputDados, { id: 'data_fim', label: 'Data Fim', mask: '99/99/9999', value: BuscaDataFim, onChange: function (event) {
                                setBuscaDataFim(event.target.value);
                            }, onBlur: function (event) {
                                filtrarGrid(TextoBusca, BuscaStatusContratosSelecionado, BuscaInstituicaoSelecionada, BuscaDataInicio, event.target.value);
                            }, size: 'small' })] })), _jsx(BoxGrid, { children: _jsx(GridComp, { columns: COLUNAS_FUNCIONALIDADE, rows: OfertasVagaTrabalhos, OPCOES_FUNCIONALIDADE: OPCOES_FUNCIONALIDADE, TotalRegistros: TotalRegistros, isLoading: isLoading, PaginationModel: PaginationModel, setPaginationModel: onPaginationModelChange, initialState: {
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'asc' }],
                            },
                        } }) })] }) })));
};
export default ContratosTrabalhoListar;
