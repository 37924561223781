var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { Box, IconButton, Tooltip, MenuItem } from '@mui/material';
import { getCodigoOfertaFormatada, getDataFormatada, MODOS } from '../../utils';
import ContainerPainel from '../../components/container_painel';
import AuthContext from '../../contexts/auth';
import { ROUTES, PAGINAS } from '../../routes';
import { BotaoAdicionar } from '../../components/botoes';
import BarraPesquisar from '../../components/grid_pesquisar';
import GridComp from '../../components/grid';
import { PaginationModelDefault } from '../../components/grid';
import { BoxPrincipal, BoxSuperior, BoxGrid } from '../../components/grid_box';
import { getOfertasVagasTrabalhos, getCombosListarOfertasVagas } from './ofertas_vagas_trabalho.service';
import { STATUS_API } from '../../api';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useNavigate } from 'react-router-dom';
import InputDados from '../../components/input_dados';
export var OfertasVagasTrabalhosListar = function (props) {
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var navigate = useNavigate();
    var _b = useState([]), InstituicoesDisponiveis = _b[0], setInstituicoesDisponiveis = _b[1];
    var _c = useState(-1), BuscaInstituicaoSelecionada = _c[0], setBuscaInstituicaoSelecionada = _c[1];
    var _d = useState([]), PerfisLaboraisDisponiveis = _d[0], setPerfisLaboraisDisponiveis = _d[1];
    var _e = useState(-1), BuscaPerfilLaboralSelecionado = _e[0], setBuscaPerfilLaboralSelecionado = _e[1];
    var _f = useState([]), TiposStatusOfertasDisponiveis = _f[0], setTiposStatusOfertasDisponiveis = _f[1];
    var _g = useState('-1'), BuscaStatusOfertasSelecionado = _g[0], setBuscaStatusOfertasSelecionado = _g[1];
    var _h = useState(''), BuscaDataInicio = _h[0], setBuscaDataInicio = _h[1];
    var _j = useState(''), BuscaDataFim = _j[0], setBuscaDataFim = _j[1];
    var _k = useState(''), TextoBusca = _k[0], setTextoBusca = _k[1];
    var _l = useState([]), OfertasVagaTrabalhos = _l[0], setOfertasVagaTrabalhos = _l[1];
    var _m = useState(0), TotalRegistros = _m[0], setTotalRegistros = _m[1];
    var _o = React.useState(PaginationModelDefault), PaginationModel = _o[0], setPaginationModel = _o[1];
    var getAcoesFuncionalidade = (useContext(AuthContext) || { getAcoesFuncionalidade: function () { return []; } }).getAcoesFuncionalidade;
    var _p = React.useState(getAcoesFuncionalidade(PAGINAS.OFERTAS_VAGAS_TRABALHO)), AcoesFuncionalidade = _p[0], setAcoesFuncionalidade = _p[1];
    useEffect(function () {
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function (TextoBuscaParm, BuscaStatusOfertasSelecionadoParm, BuscaInstituicaoSelecionadaParm, BuscaPerfilLaboralSelecionadoParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModelParm) {
        if (TextoBuscaParm === void 0) { TextoBuscaParm = TextoBusca; }
        if (BuscaStatusOfertasSelecionadoParm === void 0) { BuscaStatusOfertasSelecionadoParm = BuscaStatusOfertasSelecionado; }
        if (BuscaInstituicaoSelecionadaParm === void 0) { BuscaInstituicaoSelecionadaParm = BuscaInstituicaoSelecionada; }
        if (BuscaPerfilLaboralSelecionadoParm === void 0) { BuscaPerfilLaboralSelecionadoParm = BuscaPerfilLaboralSelecionado; }
        if (BuscaDataInicioParm === void 0) { BuscaDataInicioParm = BuscaDataInicio; }
        if (BuscaDataFimParm === void 0) { BuscaDataFimParm = BuscaDataFim; }
        if (PaginationModelParm === void 0) { PaginationModelParm = PaginationModel; }
        return __awaiter(void 0, void 0, void 0, function () {
            var CombosVagas, instituicoes, perfis_laborais, status_ofertas, Resposta, dados, total_registros;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        if (!(InstituicoesDisponiveis.length <= 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, getCombosListarOfertasVagas()];
                    case 1:
                        CombosVagas = _a.sent();
                        if (CombosVagas.Status == STATUS_API.ERRO) {
                            alert(CombosVagas.Mensagem);
                            setIsLoading(false);
                            return [2 /*return*/];
                        }
                        instituicoes = CombosVagas.instituicoes, perfis_laborais = CombosVagas.perfis_laborais, status_ofertas = CombosVagas.status_ofertas;
                        setTiposStatusOfertasDisponiveis(status_ofertas);
                        setInstituicoesDisponiveis(instituicoes);
                        setPerfisLaboraisDisponiveis(perfis_laborais);
                        _a.label = 2;
                    case 2: return [4 /*yield*/, getOfertasVagasTrabalhos(TextoBuscaParm == '' ? null : TextoBuscaParm, BuscaStatusOfertasSelecionadoParm == '-1' ? null : BuscaStatusOfertasSelecionadoParm, BuscaInstituicaoSelecionadaParm == -1 ? null : BuscaInstituicaoSelecionadaParm === null || BuscaInstituicaoSelecionadaParm === void 0 ? void 0 : BuscaInstituicaoSelecionadaParm.toString(), BuscaPerfilLaboralSelecionadoParm == -1 ? null : BuscaPerfilLaboralSelecionadoParm === null || BuscaPerfilLaboralSelecionadoParm === void 0 ? void 0 : BuscaPerfilLaboralSelecionadoParm.toString(), BuscaDataInicioParm == '' ? null : BuscaDataInicioParm, BuscaDataFimParm == '' ? null : BuscaDataFimParm, PaginationModelParm)];
                    case 3:
                        Resposta = _a.sent();
                        if (Resposta.Status == STATUS_API.ERRO) {
                            alert(Resposta.Mensagem);
                        }
                        else {
                            dados = Resposta.dados, total_registros = Resposta.total_registros;
                            setOfertasVagaTrabalhos(dados);
                            setTotalRegistros(total_registros);
                        }
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    var filtrarGrid = function (TextoBuscaParm, BuscaStatusOfertasSelecionadoParm, BuscaInstituicaoSelecionadaParm, BuscaPerfilLaboralSelecionadoParm, BuscaDataInicioParm, BuscaDataFimParm) {
        if (TextoBuscaParm === void 0) { TextoBuscaParm = TextoBusca; }
        if (BuscaStatusOfertasSelecionadoParm === void 0) { BuscaStatusOfertasSelecionadoParm = BuscaStatusOfertasSelecionado; }
        if (BuscaInstituicaoSelecionadaParm === void 0) { BuscaInstituicaoSelecionadaParm = BuscaInstituicaoSelecionada; }
        if (BuscaPerfilLaboralSelecionadoParm === void 0) { BuscaPerfilLaboralSelecionadoParm = BuscaPerfilLaboralSelecionado; }
        if (BuscaDataInicioParm === void 0) { BuscaDataInicioParm = BuscaDataInicio; }
        if (BuscaDataFimParm === void 0) { BuscaDataFimParm = BuscaDataFim; }
        setIsLoading(true);
        setTextoBusca(TextoBuscaParm);
        setBuscaStatusOfertasSelecionado(BuscaStatusOfertasSelecionadoParm);
        setBuscaInstituicaoSelecionada(BuscaInstituicaoSelecionadaParm);
        setBuscaPerfilLaboralSelecionado(BuscaPerfilLaboralSelecionadoParm);
        setBuscaDataInicio(BuscaDataInicioParm);
        setBuscaDataFim(BuscaDataFimParm);
        resetGrid(TextoBuscaParm, BuscaStatusOfertasSelecionadoParm, BuscaInstituicaoSelecionadaParm, BuscaPerfilLaboralSelecionadoParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModel);
    };
    var onPaginationModelChange = function (PaginationModelParm) {
        //console.log('PaginationModelParm : ' + JSON.stringify(PaginationModelParm));
        setPaginationModel(PaginationModelParm);
        carregarDados(TextoBusca, BuscaStatusOfertasSelecionado, BuscaInstituicaoSelecionada, BuscaPerfilLaboralSelecionado, BuscaDataInicio, BuscaDataFim, PaginationModelParm);
    };
    var resetGrid = function (TextoBuscaParm, BuscaStatusOfertasSelecionadoParm, BuscaInstituicaoSelecionadaParm, BuscaPerfilLaboralSelecionadoParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModelParm) {
        if (TextoBuscaParm === void 0) { TextoBuscaParm = TextoBusca; }
        if (BuscaStatusOfertasSelecionadoParm === void 0) { BuscaStatusOfertasSelecionadoParm = BuscaStatusOfertasSelecionado; }
        if (BuscaInstituicaoSelecionadaParm === void 0) { BuscaInstituicaoSelecionadaParm = BuscaInstituicaoSelecionada; }
        if (BuscaPerfilLaboralSelecionadoParm === void 0) { BuscaPerfilLaboralSelecionadoParm = BuscaPerfilLaboralSelecionado; }
        if (BuscaDataInicioParm === void 0) { BuscaDataInicioParm = BuscaDataInicio; }
        if (BuscaDataFimParm === void 0) { BuscaDataFimParm = BuscaDataFim; }
        var PaginationModelAux = PaginationModelParm !== null && PaginationModelParm !== void 0 ? PaginationModelParm : PaginationModel;
        PaginationModelAux = __assign(__assign({}, PaginationModelAux), { page: 0 });
        setPaginationModel(PaginationModelAux);
        carregarDados(TextoBuscaParm, BuscaStatusOfertasSelecionadoParm, BuscaInstituicaoSelecionadaParm, BuscaPerfilLaboralSelecionadoParm, BuscaDataInicioParm, BuscaDataFimParm, PaginationModelAux);
        //console.log('PaginationModelParm : ' + JSON.stringify(Pagination
    };
    var COLUNAS_FUNCIONALIDADE = [
        {
            field: 'id',
            headerName: 'Código',
            description: 'Código da Oferta',
            width: 100,
            valueGetter: function (value, row) { return getCodigoOfertaFormatada(value); },
        },
        {
            field: 'instituicao',
            headerName: 'Instituição',
            width: 300,
            valueGetter: function (value, row) { var _a, _b; return (_b = (_a = row.instituicao) === null || _a === void 0 ? void 0 : _a.dados) === null || _b === void 0 ? void 0 : _b.razao_social; },
        },
        {
            field: 'data_inicio',
            headerName: 'Data Início',
            width: 150,
            valueGetter: function (value, row) { return (value ? getDataFormatada(value) : ''); },
        },
        {
            field: 'vagas_qtd',
            headerName: 'QTD. Solicitadas',
            description: 'Quantidade de Vagas Solicitadas',
            width: 150,
        },
        {
            field: 'vagas_abertas',
            headerName: 'Abertas',
            description: 'Vagas Abertas',
            width: 100,
        },
        {
            field: 'vagas_atendidas_parcialmente',
            headerName: 'P. Atendidas',
            description: 'Vagas Atendidas Parcialmente',
            width: 110,
        },
        {
            field: 'vagas_finalizadas',
            headerName: 'Finalizadas',
            description: 'Vagas Finalizadas',
            width: 130,
        },
        {
            field: 'vagas_encerradas',
            headerName: 'Encerradas',
            description: 'Vagas Encerradas',
            width: 110,
        },
    ];
    var OPCOES_FUNCIONALIDADE = {
        ROTA: ROUTES.OFERTAS_VAGAS_TRABALHO,
        VISUALIZAR: AcoesFuncionalidade[MODOS.VISUALIZAR],
        ALTERAR: AcoesFuncionalidade[MODOS.ALTERAR],
        EXCLUIR: AcoesFuncionalidade[MODOS.EXCLUIR],
    };
    var opcaoEditarVagas = function (params) {
        var _a = params.row, id = _a.id, nome = _a.nome, situacao = _a.situacao;
        return (_jsx(Tooltip, __assign({ title: 'Editar Vagas', componentsProps: { tooltip: { sx: { fontSize: '0.9rem' } } } }, { children: _jsx(Box, __assign({ ml: 1 }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 1, marginTop: -1 }, onClick: function () {
                        navigate("".concat(ROUTES.OFERTAS_VAGAS_TRABALHO_VAGAS, "/").concat(id, "/").concat(MODOS.ALTERAR));
                    } }, { children: _jsx(GroupAddIcon, { fontSize: 'medium' }) })) })) })));
    };
    return (_jsx(ContainerPainel, __assign({ pagina: PAGINAS.OFERTAS_VAGAS_TRABALHO }, { children: _jsxs(BoxPrincipal, { children: [_jsxs(BoxSuperior, { children: [_jsx(BarraPesquisar, { filtrarGrid: filtrarGrid }), _jsx(BotaoAdicionar, { pagina: ROUTES.OFERTAS_VAGAS_TRABALHO, disabled: !AcoesFuncionalidade[MODOS.CADASTRAR] })] }), _jsxs(Box, __assign({ sx: { width: 1200, display: 'flex', flexDirection: 'row' }, mt: 2, gap: 2 }, { children: [_jsxs(InputDados, __assign({ select: true, id: 'status_ofertas' // obrigatorio
                            , label: 'Status Ofertas' // obrigatorio
                            , placeholder: 'Status Ofertas', value: BuscaStatusOfertasSelecionado, onChange: function (event) {
                                filtrarGrid(TextoBusca, event.target.value, BuscaInstituicaoSelecionada, BuscaPerfilLaboralSelecionado, BuscaDataInicio, BuscaDataFim);
                            }, size: 'small' }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), TiposStatusOfertasDisponiveis &&
                                    TiposStatusOfertasDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.nome }, { children: item.nome }), item.nome)); })] })), _jsxs(InputDados, __assign({ select: true, id: 'instituicao' // obrigatorio
                            , label: 'Institui\u00E7\u00E3o' // obrigatorio
                            , placeholder: 'Institui\u00E7\u00E3o', value: BuscaInstituicaoSelecionada, onChange: function (event) {
                                //const ValorAux = event.target.value;
                                filtrarGrid(TextoBusca, BuscaStatusOfertasSelecionado, event.target.value, BuscaPerfilLaboralSelecionado, BuscaDataInicio, BuscaDataFim);
                            }, size: 'small' }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), InstituicoesDisponiveis &&
                                    InstituicoesDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.dados.razao_social }), item.id)); })] })), _jsxs(InputDados, __assign({ select: true, id: 'perfil_laboral' // obrigatorio
                            , label: 'Perfil Laboral' // obrigatorio
                            , placeholder: 'Perfil Laboral', value: BuscaPerfilLaboralSelecionado, onChange: function (event) {
                                filtrarGrid(TextoBusca, BuscaStatusOfertasSelecionado, BuscaInstituicaoSelecionada, event.target.value, BuscaDataInicio, BuscaDataFim);
                            }, size: 'small' }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), PerfisLaboraisDisponiveis &&
                                    PerfisLaboraisDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })), _jsx(InputDados, { id: 'data_inicio', label: 'Data In\u00EDcio', mask: '99/99/9999', value: BuscaDataInicio, onChange: function (event) {
                                setBuscaDataInicio(event.target.value);
                            }, onBlur: function (event) {
                                filtrarGrid(TextoBusca, BuscaStatusOfertasSelecionado, BuscaInstituicaoSelecionada, BuscaPerfilLaboralSelecionado, event.target.value, BuscaDataFim);
                            }, size: 'small' }), _jsx(InputDados, { id: 'data_fim', label: 'Data Fim', mask: '99/99/9999', value: BuscaDataFim, onChange: function (event) {
                                setBuscaDataFim(event.target.value);
                            }, onBlur: function (event) {
                                filtrarGrid(TextoBusca, BuscaStatusOfertasSelecionado, BuscaInstituicaoSelecionada, BuscaPerfilLaboralSelecionado, BuscaDataInicio, event.target.value);
                            }, size: 'small' })] })), _jsx(BoxGrid, { children: _jsx(GridComp, { columns: COLUNAS_FUNCIONALIDADE, rows: OfertasVagaTrabalhos, OPCOES_FUNCIONALIDADE: OPCOES_FUNCIONALIDADE, OPCAO_ADICIONAL_INICIO: opcaoEditarVagas, TotalRegistros: TotalRegistros, isLoading: isLoading, PaginationModel: PaginationModel, setPaginationModel: onPaginationModelChange }) })] }) })));
};
export default OfertasVagasTrabalhosListar;
