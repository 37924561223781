import Home from '../pages/site/home';
import SystemCheck from '../pages/system/check';
import NotFoundPage from '../pages/not_found_page';
// Painel
import HomePainel from '../pages/home_painel';
import SemPermissao from '../pages/sem_permissao';
import Usuarios from '../pages/usuarios';
import UsuariosAlterar from '../pages/usuarios_alterar';
import Perfis from '../pages/perfis';
import PerfisAlterar from '../pages/perfis_alterar';
import Departamentos from '../pages/departamentos';
import DepartamentosAlterar from '../pages/departamentos_alterar';
import OrgaosEmpresas from '../pages/orgaos_empresas';
import OrgaosEmpresasAlterar from '../pages/orgaos_empresas_alterar';
import PerfilAcoes from '../pages/acoes';
import PerfilAcoesAlterar from '../pages/acoes_alterar';
import Funcionalidades from '../pages/funcionalidades';
import FuncionalidadesAlterar from '../pages/funcionalidades_alterar';
import TiposServidor from '../pages/tipos_servidores';
import TiposServidorAlterar from '../pages/tipos_servidores_alterar';
import CategoriasCNH from '../pages/categorias_cnh';
import CategoriasCNHAlterar from '../pages/categorias_cnh_alterar';
import { CidadesListar, CidadesAlterar } from '../pages/cidades';
import Estados from '../pages/estados';
import EstadosAlterar from '../pages/estados_alterar';
import GrausEscolaridade from '../pages/graus_escolaridade';
import GrausEscolaridadeAlterar from '../pages/graus_escolaridade_alterar';
import NiveisEscolaridade from '../pages/niveis_escolaridade';
import NiveisEscolaridadeAlterar from '../pages/niveis_escolaridade_alterar';
import OrgaosEmissoresRG from '../pages/orgaos_emissores_rg';
import OrgaosEmissoresRGAlterar from '../pages/orgaos_emissores_rg_alterar';
import EstadosCivis from '../pages/estados_civis';
import EstadosCivisAlterar from '../pages/estados_civis_alterar';
import TiposLogradouros from '../pages/tipos_logradouros';
import TiposLogradourosAlterar from '../pages/tipos_logradouros_alterar';
import OrientacoesSexuais from '../pages/orientacoes_sexuais';
import OrientacoesSexuaisAlterar from '../pages/orientacoes_sexuais_alterar';
import IdenditadesGenero from '../pages/identidade_genero';
import IdenditadesGeneroAlterar from '../pages/identidade_genero_alterar';
import TiposRacasCores from '../pages/tipos_racas_cores';
import TiposRacasCoresAlterar from '../pages/tipos_racas_cores_alterar';
import Instituicoes from '../pages/contratos_convenios/instituicoes';
import InstituicoesAlterar from '../pages/contratos_convenios/instituicoes_alterar';
import TiposTermosDocumentos from '../pages/contratos_convenios/tipos_termo_documentos';
import TiposTermosDocumentosAlterar from '../pages/contratos_convenios/tipos_termo_documentos_alterar';
import TiposContratos from '../pages/contratos_convenios/tipos_contratos';
import TiposContratosAlterar from '../pages/contratos_convenios/tipos_contratos_alterar';
import TiposEmpresas from '../pages/contratos_convenios/tipos_empresas';
import TiposEmpresasAlterar from '../pages/contratos_convenios/tipos_empresas_alterar';
import AgenciasBancarias from '../pages/contratos_convenios/agencias_bancarias';
import AgenciasBancariasAlterar from '../pages/contratos_convenios/agencias_bancarias_alterar';
import TiposContasBancarias from '../pages/contratos_convenios/tipos_contas_bancarias';
import TiposContasBancariasAlterar from '../pages/contratos_convenios/tipos_contas_bancarias_alterar';
import { TiposTransferenciasListar, TiposTransferenciasAlterar } from '../pages/tipos_transferencias';
import { TiposContatosAlterar, TiposContatosListar } from '../pages/tipos_contatos';
import { TiposNaturezasCrimesAlterar, TiposNaturezasCrimesListar } from '../pages/tipos_naturezas_crimes';
import { TiposSaidasAlterar, TiposSaidasListar } from '../pages/tipos_saidas';
import { CategoriasContasBancariasAlterar, CategoriasContasBancariasListar } from '../pages/categorias_contas_bancarias';
import { TiposImoveisAlterar, TiposImoveisListar } from '../pages/tipos_imoveis';
import { TiposFormacoesAlterar, TiposFormacoesListar } from '../pages/tipos_formacoes';
import { TiposDependenciasAlterar, TiposDependenciasListar } from '../pages/tipos_dependencias';
import { TiposSexosAlterar, TiposSexosListar } from '../pages/tipos_sexos';
import { TiposComorbidadesAlterar, TiposComorbidadesListar } from '../pages/tipos_comorbidades';
import { TiposRegimesAlterar, TiposRegimesListar } from '../pages/tipos_regimes';
import { TiposDocumentosListar, TiposDocumentosEditar } from '../pages/tipos_documentos';
import { ReeducandosListar, ReeducandosAlterar } from '../pages/reeducandos';
import { PerfisLaboraisListar, PerfisLaboraisAlterar } from '../pages/perfis_laboraris';
import { JornadasTrabalhoListar, JornadasTrabalhoAlterar } from '../pages/jornadas_trabalho';
import { TiposStatusVagasListar, TiposStatusVagasAlterar } from '../pages/tipos_status_vagas';
import { TiposVinculosTrabalhosAlterar, TiposVinculosTrabalhosListar } from '../pages/tipos_vinculos_trabalho';
import { OfertasVagasTrabalhosAlterar, OfertasVagasTrabalhosListar, OfertasVagasAlterarVagas } from '../pages/ofertas_vagas_trabalho';
import { SelecaoProfissionaisListar, SelecaoVagas } from '../pages/selecao_profissionais';
import { AtendimentosListar, AtendimentosEditar, AtendimentosDetalheVaga } from '../pages/atendimentos';
import { ContratosTrabalhoEditar, ContratosTrabalhoListar } from '../pages/contratos_trabalho';
import Logout from '../pages/logout';
var PrefixoAdmin = '/painel';
export var ROUTES = {
    // site
    HOME_SITE: "/",
    LOGOUT: "".concat(PrefixoAdmin, "/logout"),
    SEM_PERMISSAO: "/sem-permissao",
    SYSTEM_CHECK: "/system/check",
    USUARIOS: "".concat(PrefixoAdmin, "/usuarios"),
    PERFIS: "".concat(PrefixoAdmin, "/perfis"),
    DEPARTAMENTOS: "".concat(PrefixoAdmin, "/departamentos"),
    ORGAOS_EMPRESAS: "".concat(PrefixoAdmin, "/orgaos-empresas"),
    ACOES: "".concat(PrefixoAdmin, "/acoes"),
    FUNCIONALIDADES: "".concat(PrefixoAdmin, "/funcionalidades"),
    TIPOS_SERVIDORES: "".concat(PrefixoAdmin, "/tipos-servidores"),
    CATEGORIAS_CNH: "".concat(PrefixoAdmin, "/categorias-cnh"),
    CIDADES: "".concat(PrefixoAdmin, "/cidades"),
    ESTADOS: "".concat(PrefixoAdmin, "/estados"),
    ESTADOS_CIVIS: "".concat(PrefixoAdmin, "/estados-civis"),
    GRAUS_ESCOLARIDADE: "".concat(PrefixoAdmin, "/graus-escolaridade"),
    IDENTIDADES_GENERO: "".concat(PrefixoAdmin, "/identidades-genero"),
    NIVEIS_ESCOLARIDADE: "".concat(PrefixoAdmin, "/niveis-escolaridade"),
    ORGAOS_EMISSORES_RG: "".concat(PrefixoAdmin, "/orgaos-emissores-rg"),
    ORIENTACOES_SEXUAIS: "".concat(PrefixoAdmin, "/orientacoes-sexuais"),
    TIPOS_LOGRADOUROS: "".concat(PrefixoAdmin, "/tipos-logradouros"),
    TIPOS_RACAS_CORES: "".concat(PrefixoAdmin, "/tipos-racas-cores"),
    //CONTRATOS_CONVENIOS
    CC_INSTITUICOES: "".concat(PrefixoAdmin, "/instituicoes"),
    CC_TIPOS_TERMOS_DOCUMENTOS: "".concat(PrefixoAdmin, "/tipos-termos-documentos"),
    CC_TIPOS_EMPRESAS: "".concat(PrefixoAdmin, "/tipos-empresas"),
    CC_TIPOS_CONTRATOS: "".concat(PrefixoAdmin, "/tipos-contratos"),
    AGENCIAS_BANCARIAS: "".concat(PrefixoAdmin, "/agencias-bancarias"),
    TIPOS_CONTAS_BANCARIAS: "".concat(PrefixoAdmin, "/tipos-contas-bancarias"),
    TIPOS_CONTATOS: "".concat(PrefixoAdmin, "/tipos-contatos"),
    TIPOS_SAIDAS: "".concat(PrefixoAdmin, "/tipos-saidas"),
    TIPOS_TRANSFERENCIAS: "".concat(PrefixoAdmin, "/tipos-transferencias"),
    TIPOS_NATUREZAS_CRIMES: "".concat(PrefixoAdmin, "/tipos-naturezas-crimes"),
    CATEGORIAS_CONTAS_BANCARIAS: "".concat(PrefixoAdmin, "/categorias-contas-bancarias"),
    TIPOS_IMOVEIS: "".concat(PrefixoAdmin, "/tipos-imoveis"),
    TIPOS_SEXOS: "".concat(PrefixoAdmin, "/tipos-sexos"),
    TIPOS_COMORBIDADES: "".concat(PrefixoAdmin, "/tipos-comorbidades"),
    TIPOS_DEPENDENCIAS: "".concat(PrefixoAdmin, "/tipos-dependencias"),
    TIPOS_FORMACOES: "".concat(PrefixoAdmin, "/tipos-formacoes"),
    TIPOS_REGIMES: "".concat(PrefixoAdmin, "/tipos-regimes"),
    TIPOS_DOCUMENTOS: "".concat(PrefixoAdmin, "/tipos-documentos"),
    REEDUCANDOS: "".concat(PrefixoAdmin, "/recrutamentos"),
    PERFIS_LABORAIS: "".concat(PrefixoAdmin, "/perfis-laborais"),
    JORNADAS_TRABALHO: "".concat(PrefixoAdmin, "/jornadas-trabalho"),
    TIPOS_STATUS_VAGAS: "".concat(PrefixoAdmin, "/tipos-status-vagas"),
    TIPOS_VINCULOS_TRABALHO: "".concat(PrefixoAdmin, "/tipos-vinculos-trabalho"),
    OFERTAS_VAGAS_TRABALHO: "".concat(PrefixoAdmin, "/vagas-trabalho"),
    OFERTAS_VAGAS_TRABALHO_VAGAS: "".concat(PrefixoAdmin, "/vagas-trabalho/vagas"),
    SELECAO_PROFISSIONAIS: "".concat(PrefixoAdmin, "/selecao-profissionais"),
    SELECAO_PROFISSIONAIS_VAGAS: "".concat(PrefixoAdmin, "/selecao-profissionais/vagas"),
    ATENDIMENTOS: "".concat(PrefixoAdmin, "/atendimentos"),
    CONTRATOS_TRABALHO: "".concat(PrefixoAdmin, "/contratos-trabalho"),
    ATENDIMENTO_VISUALIZAR_VAGA: "".concat(PrefixoAdmin, "/atendimentos/vaga"),
    //painel
    HOME_PAINEL: "".concat(PrefixoAdmin),
};
export var PAGINAS = {
    // site
    HOME_SITE: "Home Site",
    //painel
    HOME_PAINEL: "".concat(PrefixoAdmin),
    SEM_PERMISSAO: "Sem Permiss\u00E3o",
    SYSTEM_CHECK: "System Check",
    USUARIOS: "Usu\u00E1rios",
    PERFIS: "Perfis",
    DEPARTAMENTOS: "Departamentos",
    ORGAOS_EMPRESAS: "Org\u00E3os / Empresas",
    ACOES: "A\u00E7\u00F5es",
    FUNCIONALIDADES: "Funcionalidades",
    TIPOS_SERVIDORES: "Tipos de Servidores",
    CATEGORIAS_CNH: "Categorias de CNH",
    CIDADES: "Cidades",
    ESTADOS: "Estados",
    ESTADOS_CIVIS: "Estados Civis",
    GRAUS_ESCOLARIDADE: "Graus de Escolaridade",
    IDENTIDADES_GENERO: "Identidades de G\u00EAnero",
    NIVEIS_ESCOLARIDADE: "N\u00EDveis de Escolaridade",
    ORGAOS_EMISSORES_RG: "\u00D3rg\u00E3os Emissores de RG",
    ORIENTACOES_SEXUAIS: "Orienta\u00E7\u00F5es Sexuais",
    TIPOS_LOGRADOUROS: "Tipos de Logradouros",
    TIPOS_RACAS_CORES: "Tipos de Ra\u00E7as / Cores",
    //CONTRATOS E CONVENIOS
    CC_INSTITUICOES: "Institui\u00E7\u00F5es",
    CC_INSTITUICOES_DADOS: "Institui\u00E7\u00F5es - Dados",
    CC_INSTITUICOES_DOCUMENTOS: "Institui\u00E7\u00F5es - Documentos",
    CC_INSTITUICOES_TERMOS: "Institui\u00E7\u00F5es - Termos / Contratos",
    CC_TIPOS_TERMOS_DOCUMENTOS: "Tipos de Termos / Documentos",
    CC_TIPOS_EMPRESAS: "Tipos de Empresas",
    CC_TIPOS_CONTRATOS: "Tipos de Contratos",
    AGENCIAS_BANCARIAS: "Ag\u00EAncias Banc\u00E1rias",
    TIPOS_CONTAS_BANCARIAS: "Tipos de Contas Banc\u00E1rias",
    NOTIFICACOES: "Notifica\u00E7\u00F5es",
    TIPOS_CONTATOS: 'Tipos de Contatos',
    TIPOS_SAIDAS: 'Tipos de Saídas',
    TIPOS_TRANSFERENCIAS: 'Tipos de Transferências',
    TIPOS_NATUREZAS_CRIMES: 'Tipos de Naturezas de Crimes',
    CATEGORIAS_CONTAS_BANCARIAS: 'Categorias de Contas Bancárias',
    TIPOS_IMOVEIS: 'Tipos de Imóveis',
    TIPOS_SEXOS: 'Tipos de Sexos',
    TIPOS_COMORBIDADES: 'Tipos de Comorbidades',
    TIPOS_DEPENDENCIAS: 'Tipos de Dependências',
    TIPOS_FORMACOES: 'Tipos de Formações',
    TIPOS_DOCUMENTOS: "Tipos de Documentos",
    //REEDUCANDOS
    REEDUCANDOS: 'Recrutamentos',
    REEDUCANDOS_DADOS_PESSOAIS_1: 'Recrutamentos Dados Pessoais 1',
    REEDUCANDOS_DADOS_PESSOAIS_2: 'Recrutamentos Dados Pessoais 2',
    REEDUCANDOS_DADOS_PESSOAIS_3: 'Recrutamentos Dados Pessoais 3',
    REEDUCANDOS_FORMACOES: 'Recrutamentos Formações',
    REEDUCANDOS_EXPERIENCIAS_PROFISSIONAIS: 'Recrutamentos Experiências Profissionais',
    REEDUCANDOS_ENDEREÇOS: 'Recrutamentos Endereços',
    REEDUCANDOS_CONTATOS: 'Recrutamentos Contatos',
    REEDUCANDOS_DOCUMENTOS_ANEXOS: 'Recrutamentos Documentos Anexos',
    REEDUCANDOS_DEPENDENCIAS: 'Recrutamentos Dependências',
    REEDUCANDOS_COMORBIDADES: 'Recrutamentos Comorbidades',
    REEDUCANDOS_OBSERVAÇÕES_ADM: 'Recrutamentos Observações ADM',
    REEDUCANDOS_OCORRENCIAS: 'Recrutamentos Informações Legais',
    REEDUCANDOS_OUTRAS_INFORMACOES: 'Recrutamentos Outras Informações',
    REEDUCANDOS_SELECAO: 'Reecrutandos Seleção',
    TIPOS_REGIMES: 'Tipos de Regimes',
    //VAGAS
    PERFIS_LABORAIS: "Perfis Laborais",
    JORNADAS_TRABALHO: "Jornadas de Trabalho",
    TIPOS_STATUS_VAGAS: "Tipos de Status de Vagas",
    TIPOS_VINCULOS_TRABALHO: "Tipos de V\u00EDnculos de Trabalho",
    OFERTAS_VAGAS_TRABALHO: "Ofertas de Vagas de Trabalho",
    OFERTAS_VAGAS_TRABALHO_VAGAS: "Vagas de Trabalho",
    SELECAO_PROFISSIONAIS: "Sele\u00E7\u00E3o de Profissionais",
    SELECAO_PROFISSIONAIS_VAGAS: "Sele\u00E7\u00E3o - Vagas de Trabalho",
    ATENDIMENTOS: "Atendimentos",
    CONTRATOS_TRABALHO: "Contratos de Trabalho",
    ATENDIMENTO_VISUALIZAR_VAGA: "Atendimento - Visualizar Vaga",
};
var RoutesConfig = [
    {
        path: '*',
        element: NotFoundPage,
    },
    {
        path: ROUTES.SYSTEM_CHECK,
        element: SystemCheck,
    },
    {
        path: ROUTES.LOGOUT,
        element: Logout,
    },
    {
        path: ROUTES.HOME_SITE,
        element: Home,
    },
    {
        path: ROUTES.HOME_PAINEL,
        element: HomePainel,
    },
    {
        path: ROUTES.SEM_PERMISSAO,
        element: SemPermissao,
    },
    {
        path: ROUTES.USUARIOS,
        element: Usuarios,
    },
    {
        path: ROUTES.USUARIOS + '/:id?/:modo',
        element: UsuariosAlterar,
    },
    {
        path: ROUTES.PERFIS,
        element: Perfis,
    },
    {
        path: ROUTES.PERFIS + '/:id?/:modo',
        element: PerfisAlterar,
    },
    {
        path: ROUTES.DEPARTAMENTOS,
        element: Departamentos,
    },
    {
        path: ROUTES.DEPARTAMENTOS + '/:id?/:modo',
        element: DepartamentosAlterar,
    },
    {
        path: ROUTES.ORGAOS_EMPRESAS,
        element: OrgaosEmpresas,
    },
    {
        path: ROUTES.ORGAOS_EMPRESAS + '/:id?/:modo',
        element: OrgaosEmpresasAlterar,
    },
    {
        path: ROUTES.ACOES,
        element: PerfilAcoes,
    },
    {
        path: ROUTES.ACOES + '/:id?/:modo',
        element: PerfilAcoesAlterar,
    },
    {
        path: ROUTES.FUNCIONALIDADES,
        element: Funcionalidades,
    },
    {
        path: ROUTES.FUNCIONALIDADES + '/:id?/:modo',
        element: FuncionalidadesAlterar,
    },
    {
        path: ROUTES.TIPOS_SERVIDORES,
        element: TiposServidor,
    },
    {
        path: ROUTES.TIPOS_SERVIDORES + '/:id?/:modo',
        element: TiposServidorAlterar,
    },
    {
        path: ROUTES.CATEGORIAS_CNH,
        element: CategoriasCNH,
    },
    {
        path: ROUTES.CATEGORIAS_CNH + '/:id?/:modo',
        element: CategoriasCNHAlterar,
    },
    {
        path: ROUTES.CIDADES,
        element: CidadesListar,
    },
    {
        path: ROUTES.CIDADES + '/:id?/:modo',
        element: CidadesAlterar,
    },
    {
        path: ROUTES.ESTADOS,
        element: Estados,
    },
    {
        path: ROUTES.ESTADOS + '/:id?/:modo',
        element: EstadosAlterar,
    },
    {
        path: ROUTES.GRAUS_ESCOLARIDADE,
        element: GrausEscolaridade,
    },
    {
        path: ROUTES.GRAUS_ESCOLARIDADE + '/:id?/:modo',
        element: GrausEscolaridadeAlterar,
    },
    {
        path: ROUTES.NIVEIS_ESCOLARIDADE,
        element: NiveisEscolaridade,
    },
    {
        path: ROUTES.NIVEIS_ESCOLARIDADE + '/:id?/:modo',
        element: NiveisEscolaridadeAlterar,
    },
    {
        path: ROUTES.ORGAOS_EMISSORES_RG,
        element: OrgaosEmissoresRG,
    },
    {
        path: ROUTES.ORGAOS_EMISSORES_RG + '/:id?/:modo',
        element: OrgaosEmissoresRGAlterar,
    },
    {
        path: ROUTES.ESTADOS_CIVIS,
        element: EstadosCivis,
    },
    {
        path: ROUTES.ESTADOS_CIVIS + '/:id?/:modo',
        element: EstadosCivisAlterar,
    },
    {
        path: ROUTES.TIPOS_LOGRADOUROS,
        element: TiposLogradouros,
    },
    {
        path: ROUTES.TIPOS_LOGRADOUROS + '/:id?/:modo',
        element: TiposLogradourosAlterar,
    },
    {
        path: ROUTES.ORIENTACOES_SEXUAIS,
        element: OrientacoesSexuais,
    },
    {
        path: ROUTES.ORIENTACOES_SEXUAIS + '/:id?/:modo',
        element: OrientacoesSexuaisAlterar,
    },
    {
        path: ROUTES.IDENTIDADES_GENERO,
        element: IdenditadesGenero,
    },
    {
        path: ROUTES.IDENTIDADES_GENERO + '/:id?/:modo',
        element: IdenditadesGeneroAlterar,
    },
    {
        path: ROUTES.TIPOS_RACAS_CORES,
        element: TiposRacasCores,
    },
    {
        path: ROUTES.TIPOS_RACAS_CORES + '/:id?/:modo',
        element: TiposRacasCoresAlterar,
    },
    {
        path: ROUTES.CC_INSTITUICOES,
        element: Instituicoes,
    },
    {
        path: ROUTES.CC_INSTITUICOES + '/:id?/:modo',
        element: InstituicoesAlterar,
    },
    {
        path: ROUTES.CC_TIPOS_CONTRATOS,
        element: TiposContratos,
    },
    {
        path: ROUTES.CC_TIPOS_CONTRATOS + '/:id?/:modo',
        element: TiposContratosAlterar,
    },
    {
        path: ROUTES.CC_TIPOS_TERMOS_DOCUMENTOS,
        element: TiposTermosDocumentos,
    },
    {
        path: ROUTES.CC_TIPOS_TERMOS_DOCUMENTOS + '/:id?/:modo',
        element: TiposTermosDocumentosAlterar,
    },
    {
        path: ROUTES.CC_TIPOS_EMPRESAS,
        element: TiposEmpresas,
    },
    {
        path: ROUTES.CC_TIPOS_EMPRESAS + '/:id?/:modo',
        element: TiposEmpresasAlterar,
    },
    {
        path: ROUTES.AGENCIAS_BANCARIAS,
        element: AgenciasBancarias,
    },
    {
        path: ROUTES.AGENCIAS_BANCARIAS + '/:id?/:modo',
        element: AgenciasBancariasAlterar,
    },
    {
        path: ROUTES.TIPOS_CONTAS_BANCARIAS,
        element: TiposContasBancarias,
    },
    {
        path: ROUTES.TIPOS_CONTAS_BANCARIAS + '/:id?/:modo',
        element: TiposContasBancariasAlterar,
    },
    {
        path: ROUTES.TIPOS_CONTATOS,
        element: TiposContatosListar,
    },
    {
        path: ROUTES.TIPOS_CONTATOS + '/:id?/:modo',
        element: TiposContatosAlterar,
    },
    {
        path: ROUTES.TIPOS_TRANSFERENCIAS,
        element: TiposTransferenciasListar,
    },
    {
        path: ROUTES.TIPOS_TRANSFERENCIAS + '/:id?/:modo',
        element: TiposTransferenciasAlterar,
    },
    {
        path: ROUTES.TIPOS_SAIDAS,
        element: TiposSaidasListar,
    },
    {
        path: ROUTES.TIPOS_SAIDAS + '/:id?/:modo',
        element: TiposSaidasAlterar,
    },
    {
        path: ROUTES.TIPOS_NATUREZAS_CRIMES,
        element: TiposNaturezasCrimesListar,
    },
    {
        path: ROUTES.TIPOS_NATUREZAS_CRIMES + '/:id?/:modo',
        element: TiposNaturezasCrimesAlterar,
    },
    {
        path: ROUTES.TIPOS_CONTATOS,
        element: TiposContatosListar,
    },
    {
        path: ROUTES.TIPOS_CONTATOS + '/:id?/:modo',
        element: TiposContatosAlterar,
    },
    {
        path: ROUTES.CATEGORIAS_CONTAS_BANCARIAS,
        element: CategoriasContasBancariasListar,
    },
    {
        path: ROUTES.CATEGORIAS_CONTAS_BANCARIAS + '/:id?/:modo',
        element: CategoriasContasBancariasAlterar,
    },
    {
        path: ROUTES.TIPOS_IMOVEIS,
        element: TiposImoveisListar,
    },
    {
        path: ROUTES.TIPOS_IMOVEIS + '/:id?/:modo',
        element: TiposImoveisAlterar,
    },
    {
        path: ROUTES.TIPOS_SEXOS,
        element: TiposSexosListar,
    },
    {
        path: ROUTES.TIPOS_SEXOS + '/:id?/:modo',
        element: TiposSexosAlterar,
    },
    {
        path: ROUTES.TIPOS_COMORBIDADES,
        element: TiposComorbidadesListar,
    },
    {
        path: ROUTES.TIPOS_COMORBIDADES + '/:id?/:modo',
        element: TiposComorbidadesAlterar,
    },
    {
        path: ROUTES.TIPOS_DEPENDENCIAS,
        element: TiposDependenciasListar,
    },
    {
        path: ROUTES.TIPOS_DEPENDENCIAS + '/:id?/:modo',
        element: TiposDependenciasAlterar,
    },
    {
        path: ROUTES.TIPOS_FORMACOES,
        element: TiposFormacoesListar,
    },
    {
        path: ROUTES.TIPOS_FORMACOES + '/:id?/:modo',
        element: TiposFormacoesAlterar,
    },
    {
        path: ROUTES.TIPOS_REGIMES,
        element: TiposRegimesListar,
    },
    {
        path: ROUTES.TIPOS_REGIMES + '/:id?/:modo',
        element: TiposRegimesAlterar,
    },
    {
        path: ROUTES.TIPOS_DOCUMENTOS,
        element: TiposDocumentosListar,
    },
    {
        path: ROUTES.TIPOS_DOCUMENTOS + '/:id?/:modo',
        element: TiposDocumentosEditar,
    },
    {
        path: ROUTES.REEDUCANDOS,
        element: ReeducandosListar,
    },
    {
        path: ROUTES.REEDUCANDOS + '/:id?/:modo',
        element: ReeducandosAlterar,
    },
    {
        path: ROUTES.PERFIS_LABORAIS,
        element: PerfisLaboraisListar,
    },
    {
        path: ROUTES.PERFIS_LABORAIS + '/:id?/:modo',
        element: PerfisLaboraisAlterar,
    },
    {
        path: ROUTES.JORNADAS_TRABALHO,
        element: JornadasTrabalhoListar,
    },
    {
        path: ROUTES.JORNADAS_TRABALHO + '/:id?/:modo',
        element: JornadasTrabalhoAlterar,
    },
    {
        path: ROUTES.TIPOS_STATUS_VAGAS,
        element: TiposStatusVagasListar,
    },
    {
        path: ROUTES.TIPOS_STATUS_VAGAS + '/:id?/:modo',
        element: TiposStatusVagasAlterar,
    },
    {
        path: ROUTES.TIPOS_VINCULOS_TRABALHO,
        element: TiposVinculosTrabalhosListar,
    },
    {
        path: ROUTES.TIPOS_VINCULOS_TRABALHO + '/:id?/:modo',
        element: TiposVinculosTrabalhosAlterar,
    },
    {
        path: ROUTES.OFERTAS_VAGAS_TRABALHO,
        element: OfertasVagasTrabalhosListar,
    },
    {
        path: ROUTES.OFERTAS_VAGAS_TRABALHO + '/:id?/:modo',
        element: OfertasVagasTrabalhosAlterar,
    },
    {
        path: ROUTES.OFERTAS_VAGAS_TRABALHO_VAGAS,
        element: OfertasVagasAlterarVagas,
    },
    {
        path: ROUTES.OFERTAS_VAGAS_TRABALHO_VAGAS + '/:id?/:modo',
        element: OfertasVagasAlterarVagas,
    },
    {
        path: ROUTES.SELECAO_PROFISSIONAIS,
        element: SelecaoProfissionaisListar,
    },
    {
        path: ROUTES.SELECAO_PROFISSIONAIS + '/:id?/:modo',
        element: SelecaoProfissionaisListar,
    },
    {
        path: ROUTES.SELECAO_PROFISSIONAIS_VAGAS,
        element: SelecaoVagas,
    },
    {
        path: ROUTES.SELECAO_PROFISSIONAIS_VAGAS + '/:id?/:modo',
        element: SelecaoVagas,
    },
    {
        path: ROUTES.ATENDIMENTOS,
        element: AtendimentosListar,
    },
    {
        path: ROUTES.ATENDIMENTOS + '/:id?/:modo',
        element: AtendimentosEditar,
    },
    {
        path: ROUTES.ATENDIMENTO_VISUALIZAR_VAGA + '/:id?/:modo',
        element: AtendimentosDetalheVaga,
    },
    {
        path: ROUTES.CONTRATOS_TRABALHO,
        element: ContratosTrabalhoListar,
    },
    {
        path: ROUTES.CONTRATOS_TRABALHO + '/:id?/:modo',
        element: ContratosTrabalhoEditar,
    },
];
export default RoutesConfig;
