var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { MODOS } from '../../utils';
import { useNavigate } from 'react-router-dom';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DataGrid } from '@mui/x-data-grid';
import './data_grid_styles.css';
var Grid = function (props) {
    var columns = props.columns, rows = props.rows, OPCOES_FUNCIONALIDADE = props.OPCOES_FUNCIONALIDADE, OPCAO_ADICIONAL = props.OPCAO_ADICIONAL, OPCAO_ADICIONAL_INICIO = props.OPCAO_ADICIONAL_INICIO, TotalRegistros = props.TotalRegistros, isLoading = props.isLoading, PaginationModel = props.PaginationModel, setPaginationModel = props.setPaginationModel, rest = __rest(props, ["columns", "rows", "OPCOES_FUNCIONALIDADE", "OPCAO_ADICIONAL", "OPCAO_ADICIONAL_INICIO", "TotalRegistros", "isLoading", "PaginationModel", "setPaginationModel"]);
    var navigate = useNavigate();
    // Opções consultar, editar e excluir
    if (OPCOES_FUNCIONALIDADE) {
        if (OPCOES_FUNCIONALIDADE.VISUALIZAR || OPCOES_FUNCIONALIDADE.ALTERAR || OPCOES_FUNCIONALIDADE.EXCLUIR) {
            columns.push({
                field: 'Opções',
                headerName: '',
                align: 'right',
                flex: 1,
                minWidth: 150,
                renderCell: function (params) { return (_jsxs(Box, __assign({ flex: 1, display: 'flex', justifyContent: 'flex-end' }, { children: [OPCAO_ADICIONAL_INICIO && OPCAO_ADICIONAL_INICIO(params), OPCOES_FUNCIONALIDADE.VISUALIZAR && (_jsx(Tooltip, __assign({ title: 'Visualizar', componentsProps: { tooltip: { sx: { fontSize: '0.9rem' } } } }, { children: _jsx(Box, __assign({ bgcolor: 'secondary.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 1, marginTop: -1 }, onClick: function () {
                                        navigate("".concat(OPCOES_FUNCIONALIDADE.ROTA, "/").concat(params.row.id, "/").concat(MODOS.VISUALIZAR));
                                    } }, { children: _jsx(VisibilityOutlinedIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) })) }))), OPCOES_FUNCIONALIDADE.ALTERAR && (_jsx(Tooltip, __assign({ title: 'Alterar', componentsProps: { tooltip: { sx: { fontSize: '0.9rem' } } } }, { children: _jsx(Box, __assign({ bgcolor: 'primary.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 0, marginTop: -1 }, onClick: function () {
                                        navigate("".concat(OPCOES_FUNCIONALIDADE.ROTA, "/").concat(params.row.id, "/").concat(MODOS.ALTERAR));
                                    } }, { children: _jsx(ModeEditOutlinedIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) })) }))), OPCOES_FUNCIONALIDADE.EXCLUIR && (_jsx(Tooltip, __assign({ title: 'Excluir', componentsProps: { tooltip: { sx: { fontSize: '0.9rem' } } } }, { children: _jsx(Box, __assign({ bgcolor: 'error.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 0, marginTop: -1 }, onClick: function () {
                                        navigate("".concat(OPCOES_FUNCIONALIDADE.ROTA, "/").concat(params.row.id, "/").concat(MODOS.EXCLUIR));
                                    } }, { children: _jsx(DeleteOutlineIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) })) }))), OPCAO_ADICIONAL && OPCAO_ADICIONAL(params)] }))); },
            });
        }
    }
    var onPaginationModelChange = function (PaginationModelParm) {
        if (PaginationModel && PaginationModelParm.pageSize !== PaginationModel.pageSize) {
            setPaginationModel && setPaginationModel(__assign(__assign({}, PaginationModelParm), { page: 0 })); //zerando a paginação
        }
        else {
            setPaginationModel && setPaginationModel(PaginationModelParm);
        }
        //console.log('PaginationModelParm : ' + JSON.stringify(PaginationModelParm));
    };
    return (_jsx(DataGrid, __assign({ rows: rows, columns: columns, initialState: {
            pagination: {
                paginationModel: { pageSize: 15, page: 0 }, // Define o valor padrão de pageSize
            },
        }, 
        //checkboxSelection
        disableRowSelectionOnClick: true, disableVirtualization: true, disableColumnFilter: true, disableColumnMenu: true, disableColumnSelector: true, autoHeight: true, slotProps: {
            pagination: {
                labelRowsPerPage: 'Linhas por página',
            },
        }, rowCount: TotalRegistros, loading: isLoading, paginationModel: PaginationModel, pageSizeOptions: [15, 30, 50, 100], paginationMode: 'server', onPaginationModelChange: function (parm) { return onPaginationModelChange(parm); }, rowHeight: 35, localeText: {
            noRowsLabel: 'Nenhum registro encontrado',
            MuiTablePagination: { labelDisplayedRows: function (_a) {
                    var from = _a.from, to = _a.to, count = _a.count;
                    return "".concat(from, "-").concat(to, " de ").concat(count !== -1 ? count : "mais de ".concat(to));
                } },
        }, getRowClassName: function (params) { return (params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'); }, 
        //paginationMeta={hasNextPage={true}}
        //disableColumnResize={true}
        sx: {
            '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#DCDCDC',
                minHeight: 35,
                height: 35,
                maxHeight: 35,
                padding: '0 8px',
            },
            '& .MuiDataGrid-columnHeaders': {
                minHeight: 35,
                height: 35,
                maxHeight: 35,
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                lineHeight: '35px',
                fontSize: '0.875rem',
            },
        } }, rest)));
};
export var PaginationModelDefault = { page: 0, pageSize: 15 };
export default Grid;
