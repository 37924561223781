var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/*eslint no-useless-escape: 0*/
import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, IconButton, Grid, Dialog, DialogActions, DialogContent, DialogTitle, } from '@mui/material';
import GridComp from '../../../../components/grid';
import { MODOS, getDataFormatada } from '../../../../utils';
import { ROUTES, PAGINAS } from '../../../../routes';
import AuthContext from '../../../../contexts/auth';
import { BoxBotoes } from '../../../../components/form_box';
import Loading from '../../../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { getReeducandoEProfissionais, STATUS_API } from './services';
import { BotaoFechar, BotaoAuxiliar } from '../../../../components/botoes';
import { InputDados } from '../../../../components/input_dados';
import { VisibilityOutlined as VisibilityOutlinedIcon, } from '@mui/icons-material';
export var ReeducandosExperienciasProfissionais = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var _o = useContext(AuthContext) || { DEV: false, addMensagem: function (mensagem) { }, getAcoesFuncionalidade: function () { return []; } }, DEV = _o.DEV, addMensagem = _o.addMensagem, getAcoesFuncionalidade = _o.getAcoesFuncionalidade;
    var _p = React.useState(getAcoesFuncionalidade(PAGINAS.REEDUCANDOS_EXPERIENCIAS_PROFISSIONAIS)), AcoesFuncionalidade = _p[0], setAcoesFuncionalidade = _p[1];
    var navigate = useNavigate();
    var _q = useState(true), loading = _q[0], setLoading = _q[1];
    var _r = useState(true), LoadingInicio = _r[0], setLoadingInicio = _r[1];
    var _s = useParams(), id = _s.id, modo = _s.modo;
    var _t = useState([]), ExperienciasProfissionais = _t[0], setExperienciasProfissionais = _t[1];
    var _u = useState(), ExperienciaSelecionada = _u[0], setExperienciaSelecionada = _u[1];
    var _v = useState(false), PopupAberto = _v[0], setPopupAberto = _v[1];
    var _w = useState(), EditarID = _w[0], setEditarID = _w[1];
    var setAbaAtiva = props.setAbaAtiva;
    var DESABILITAR_CAMPOS = modo == MODOS.VISUALIZAR || modo == MODOS.EXCLUIR;
    useEffect(function () {
        //console.log('id', id);
        //console.log('modo', modo);
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var ReeducandoExperienciasProfissionais;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!id) return [3 /*break*/, 2];
                    return [4 /*yield*/, getReeducandoEProfissionais(id)];
                case 1:
                    ReeducandoExperienciasProfissionais = _a.sent();
                    if (ReeducandoExperienciasProfissionais.Status == STATUS_API.ERRO) {
                        alert(ReeducandoExperienciasProfissionais.Mensagem);
                        return [2 /*return*/];
                    }
                    else {
                        setExperienciasProfissionais(ReeducandoExperienciasProfissionais.experiencias_profissionais);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    alert('ID do reeducando não informado');
                    _a.label = 3;
                case 3:
                    setLoadingInicio(false);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var COLUNAS_ARQUIVOS = [
        {
            field: 'instituicao',
            headerName: 'Instituição',
            width: 250,
            valueGetter: function (value, row) { var _a, _b; return (_b = (_a = row.instituicao) === null || _a === void 0 ? void 0 : _a.dados) === null || _b === void 0 ? void 0 : _b.razao_social; },
        },
        {
            field: 'perfil_laboral',
            headerName: 'Perfil',
            width: 200,
            valueGetter: function (value, row) { var _a; return (_a = row.perfil_laboral) === null || _a === void 0 ? void 0 : _a.nome; },
        },
        {
            field: 'data_inicio',
            headerName: 'Data de Início',
            width: 120,
            valueGetter: function (value, row) { return (value ? getDataFormatada(value) : ''); },
        },
        {
            field: 'data_desligamento',
            headerName: 'Data Fim',
            width: 120,
            valueGetter: function (value, row) { var _a, _b; return (((_a = row.contrato_trabalho) === null || _a === void 0 ? void 0 : _a.data_desligamento) ? getDataFormatada((_b = row.contrato_trabalho) === null || _b === void 0 ? void 0 : _b.data_desligamento) : ''); },
        },
        {
            field: 'tipo_vinculo',
            headerName: 'Tipo de Vínculo',
            width: 200,
            valueGetter: function (value, row) { var _a; return (_a = row.tipo_vinculo) === null || _a === void 0 ? void 0 : _a.nome; },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            valueGetter: function (value, row) { var _a; return (_a = row.contrato_trabalho) === null || _a === void 0 ? void 0 : _a.status; },
        },
        {
            field: 'Opções',
            headerName: '',
            align: 'right',
            flex: 1,
            minWidth: 120,
            renderCell: function (params) { return (_jsx(Box, __assign({ flex: 1, display: 'flex', justifyContent: 'flex-end' }, { children: _jsx(Box, __assign({ bgcolor: 'secondary.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 1, marginTop: -1 }, onClick: function () {
                            setExperienciaSelecionada(params.row);
                            setPopupAberto(true);
                        }, disabled: !AcoesFuncionalidade['VISUALIZAR'] }, { children: _jsx(VisibilityOutlinedIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) })) }))); },
        },
    ];
    var handleClose = function () {
        setPopupAberto(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ mt: 8 }, { children: _jsx(GridComp, { rows: ExperienciasProfissionais, columns: COLUNAS_ARQUIVOS, loading: loading, paginationMode: 'client', onPaginationModelChange: function () { } }) })), _jsxs(BoxBotoes, __assign({ sx: { marginTop: 10 } }, { children: [_jsx(BotaoAuxiliar, __assign({ backgroundColor: 'terceary.main', onClick: function () { return setAbaAtiva(4); } }, { children: "Voltar" })), _jsx(BotaoFechar, { modo: modo, pagina: ROUTES.REEDUCANDOS })] })), ExperienciaSelecionada && (_jsxs(Dialog, __assign({ open: PopupAberto, onClose: handleClose, maxWidth: 'xl', fullWidth: true }, { children: [_jsx(DialogTitle, { children: "Experi\u00EAncia" }), _jsx(DialogContent, __assign({ style: { margin: 10, padding: 10 } }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, { id: 'instituicao', label: 'Institui\u00E7\u00E3o', value: (_b = (_a = ExperienciaSelecionada.instituicao) === null || _a === void 0 ? void 0 : _a.dados) === null || _b === void 0 ? void 0 : _b.razao_social, disabled: true }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, { id: 'perfil_laboral', label: 'Perfil', value: (_c = ExperienciaSelecionada.perfil_laboral) === null || _c === void 0 ? void 0 : _c.nome, disabled: true }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, { id: 'data_inicio', label: 'Data de In\u00EDcio', value: getDataFormatada(ExperienciaSelecionada.data_inicio), disabled: true }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, { id: 'data_desligamento', label: 'Data de Desligamento', value: ((_d = ExperienciaSelecionada.contrato_trabalho) === null || _d === void 0 ? void 0 : _d.data_desligamento)
                                            ? getDataFormatada((_e = ExperienciaSelecionada.contrato_trabalho) === null || _e === void 0 ? void 0 : _e.data_desligamento)
                                            : '', disabled: true }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, { id: 'tipo_vinculo', label: 'Tipo de V\u00EDnculo', value: (_f = ExperienciaSelecionada.tipo_vinculo) === null || _f === void 0 ? void 0 : _f.nome, disabled: true }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, { id: 'tipo_contrato', label: 'Tipo de Contrato', value: (_g = ExperienciaSelecionada.tipo_contrato) === null || _g === void 0 ? void 0 : _g.nome, disabled: true }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, { id: 'Motivo de Sa\u00EDda', label: 'Motivo de Sa\u00EDda', value: (_j = (_h = ExperienciaSelecionada.contrato_trabalho) === null || _h === void 0 ? void 0 : _h.desligamento_motivo_saida) === null || _j === void 0 ? void 0 : _j.nome, disabled: true }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, { id: 'status', label: 'Status', value: (_k = ExperienciaSelecionada.contrato_trabalho) === null || _k === void 0 ? void 0 : _k.status, disabled: true }) })), ((_l = ExperienciaSelecionada.contrato_trabalho) === null || _l === void 0 ? void 0 : _l.status) == 'ENCERRADO' && (_jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, { id: 'motivo_desligamento', label: 'Observa\u00E7\u00E3o de Desligamento', value: (_m = ExperienciaSelecionada.contrato_trabalho) === null || _m === void 0 ? void 0 : _m.desligamento_observacao, disabled: true }) })))] })) })), _jsx(DialogActions, __assign({ style: { padding: 20 } }, { children: _jsx(Button, __assign({ onClick: handleClose }, { children: "Cancelar" })) }))] }))), loading && _jsx(Loading, {})] }));
};
export default ReeducandosExperienciasProfissionais;
